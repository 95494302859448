/* eslint-disable react/jsx-key */
/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";
import { Link } from "react-router-dom";
// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import { CopyBlock } from "react-code-blocks";
function Guide({ title, elements, nextLink }) {
  return (
    <MKBox component="section">
      <Container>
        <Grid container item xs={12} justifyContent="center" mx="auto">
          <Grid container justifyContent="center" py={6}>
            <Grid item xs={12} md={10} mx={{ xs: "auto", sm: 6, md: 1 }}>
              <MKBox>
                <MKTypography variant="h3" textAlign="center" width="100%" mb={10}>
                  {title}
                </MKTypography>
              </MKBox>
              {elements.map((x) => {
                if (x.type == "text") {
                  return (
                    <MKTypography variant="body1" fontWeight="light" color="text" mb={10}>
                      {x.text}
                    </MKTypography>
                  );
                } else if (x.type == "title") {
                  return (
                    <MKTypography variant="h3" color="text" mb={10}>
                      {x.text}
                    </MKTypography>
                  );
                } else if (x.type == "code") {
                  return (
                    <MKBox mb={10}>
                      <CopyBlock
                        text={x.text}
                        language={"bash"}
                        showLineNumbers={false}
                        wrapLines
                        theme="dracula"
                      />
                    </MKBox>
                  );
                } else if (x.type == "image") {
                  return <MKBox component="img" src={x.image} fluid width="100%" mb={10} />;
                } else if (x.type == "textsList") {
                  return (
                    <MKBox mb={10}>
                      <ul>
                        {x.textsList.map((y) => {
                          return (
                            <MKTypography variant="body1" fontWeight="light" color="text">
                              {<li>{y}</li>}
                            </MKTypography>
                          );
                        })}
                      </ul>
                    </MKBox>
                  );
                } else if (x.type == "objectsList") {
                  return (
                    <MKBox mb={10}>
                      <ul>
                        {x.objectsList.map((y) => {
                          return (
                            <li>
                              <Container>
                                <Grid container>
                                  <Grid item xs={12}>
                                    <MKTypography variant="h5">{y.title}</MKTypography>
                                  </Grid>
                                  <Grid item xs={4} m={1}>
                                    <MKBox component="img" src={y.image} fluid />
                                  </Grid>
                                  <Grid item xs={7}>
                                    <MKTypography variant="body1">{y.text}</MKTypography>
                                  </Grid>
                                </Grid>
                              </Container>
                            </li>
                          );
                        })}
                      </ul>
                    </MKBox>
                  );
                }
                return <p>no data</p>;
              })}
              {nextLink == undefined ? (
                <></>
              ) : (
                <MKTypography variant="body1" fontWeight="light" color="text">
                  <MKTypography
                    component={Link}
                    to={nextLink}
                    href={(e) => e.preventDefault()}
                    variant="body1"
                    fontWeight="light"
                    color="info"
                    mt={3}
                    sx={{
                      width: "max-content",
                      display: "flex",
                      alignItems: "center",

                      "& .material-icons-round": {
                        transform: `translateX(3px)`,
                        transition: "transform 0.2s cubic-bezier(0.34, 1.61, 0.7, 1.3)",
                      },

                      "&:hover .material-icons-round, &:focus .material-icons-round": {
                        transform: `translateX(6px)`,
                      },
                    }}
                  >
                    Go to the next section <Icon sx={{ fontWeight: "bold" }}>arrow_forward</Icon>
                  </MKTypography>
                </MKTypography>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

Guide.propTypes = {
  elements: PropTypes.arrayOf(
    PropTypes.oneOfType([
      // Text
      PropTypes.shape({
        type: PropTypes.string.isRequired,
        text: PropTypes.string.isRequired,
      }),
      // Image
      PropTypes.shape({
        type: PropTypes.string.isRequired,
        image: PropTypes.string.isRequired,
      }),
      // TextsList
      PropTypes.shape({
        type: PropTypes.string.isRequired,
        textsList: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
      }),
      // ObjectsList
      PropTypes.shape({
        type: PropTypes.string.isRequired,
        objectsList: PropTypes.shape({
          image: PropTypes.string,
          title: PropTypes.string,
          text: PropTypes.string,
        }).isRequired,
      }),
    ])
  ).isRequired,
  title: PropTypes.string.isRequired,
  nextLink: PropTypes.string,
};
export default Guide;
