/**
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

/** 
  All of the routes for the Material Kit 2 React are added here,
  You can add a new route, customize the routes and delete the routes here.

  Once you add a new route on this file it will be visible automatically on
  the Navbar.

  For adding a new route you can follow the existing routes in the routes array.
  1. The `name` key is used for the name of the route on the Navbar.
  2. The `icon` key is used for the icon of the route on the Navbar.
  3. The `collapse` key is used for making a collapsible item on the Navbar that contains other routes
  inside (nested routes), you need to pass the nested routes inside an array as a value for the `collapse` key.
  4. The `route` key is used to store the route location which is used for the react router.
  5. The `href` key is used to store the external links location.
  6. The `component` key is used to store the component of its route.
  7. The `dropdown` key is used to define that the item should open a dropdown for its collapse items .
  8. The `description` key is used to define the description of
          a route under its name.
  9. The `columns` key is used to define that how the content should look inside the dropdown menu as columns,
          you can set the columns amount based on this key.
  10. The `rowsPerColumn` key is used to define that how many rows should be in a column.
*/

// @mui material components
import Icon from "@mui/material/Icon";

// @mui icons
import GitHubIcon from "@mui/icons-material/GitHub";

// Pages
//import GetStarted from "layouts/pages/landing-pages/get-started";
import GuidePage from "pages/LandingPages/Guide";
import GetStartedData from "assets/GetStarted.json";
import InstallOsData from "assets/InstallOs.json";
import InstallNodeJsData from "assets/InstallNodeJs.json";
import InstallAudioData from "assets/InstallAudio.json";
import InstallPiWebRadioData from "assets/InstallPiWebRadio.json";
import MountingData from "assets/Mounting.json";

const routes = [
  {
    name: "Docs",
    icon: <Icon>articles</Icon>,
    columns: 1,
    rowsPerColumn: 2,
    collapse: [
      {
        name: "get started",
        collapse: [
          {
            name: "what you need",
            route: "/pages/get-started",
            component: (
              <GuidePage
                title={GetStartedData.title}
                elements={GetStartedData.elements}
                nextLink={GetStartedData.nextLink}
              />
            ),
          },
        ],
      },
      {
        name: "Software",
        collapse: [
          {
            name: "install OS",
            route: "/pages/install-os",
            component: (
              <GuidePage
                title={InstallOsData.title}
                elements={InstallOsData.elements}
                nextLink={InstallOsData.nextLink}
              />
            ),
          },
          {
            name: "install NodeJs",
            route: "/pages/install-nodejs",
            component: (
              <GuidePage
                title={InstallNodeJsData.title}
                elements={InstallNodeJsData.elements}
                nextLink={InstallNodeJsData.nextLink}
              />
            ),
          },
          {
            name: "install Audio Hat",
            route: "/pages/install-audio",
            component: (
              <GuidePage
                title={InstallAudioData.title}
                elements={InstallAudioData.elements}
                nextLink={InstallAudioData.nextLink}
              />
            ),
          },
          {
            name: "install PiWebRadio",
            route: "/pages/install-piwebradio",
            component: (
              <GuidePage
                title={InstallPiWebRadioData.title}
                elements={InstallPiWebRadioData.elements}
                nextLink={InstallPiWebRadioData.nextLink}
              />
            ),
          },
        ],
      },
      {
        name: "Hardware",
        collapse: [
          {
            name: "mounting",
            route: "/pages/mounting",
            component: (
              <GuidePage
                title={MountingData.title}
                elements={MountingData.elements}
                nextLink={MountingData.nextLink}
              />
            ),
          },
        ],
      },
    ],
  },
  {
    name: "github",
    icon: <GitHubIcon />,
    href: "https://github.com/kevincastejon/js-raspberrypi-piwebradio-webapp-server",
  },
];

export default routes;
