/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// Material Kit 2 React components
import MKBox from "components/MKBox";

// Material Kit 2 React examples
import DefaultNavbar from "examples/Navbars/DefaultNavbar";

// Author page sections
import GuideComp from "pages/LandingPages/Guide/sections/Guide";
import DefaultFooter from "examples/Footers/DefaultFooter";

// Routes
import routes from "routes";

// Images
import bgImage from "assets/images/bkg2.png";

function GuidePage({ title, elements, nextLink }) {
  return (
    <>
      <DefaultNavbar routes={routes} sticky />
      <MKBox bgColor="white">
        <MKBox
          minHeight="25rem"
          width="100%"
          sx={{
            backgroundImage: ({ functions: { linearGradient, rgba }, palette: { gradients } }) =>
              `${linearGradient(
                rgba(gradients.dark.main, 0.8),
                rgba(gradients.dark.state, 0.8)
              )}, url(${bgImage})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            display: "grid",
            placeItems: "center",
          }}
        />
        <GuideComp title={title} elements={elements} nextLink={nextLink} />
        <MKBox>
          <DefaultFooter />
        </MKBox>
      </MKBox>
    </>
  );
}
GuidePage.propTypes = {
  elements: PropTypes.arrayOf(
    PropTypes.oneOfType([
      // Text
      PropTypes.shape({
        type: PropTypes.string.isRequired,
        text: PropTypes.string.isRequired,
      }),
      // Image
      PropTypes.shape({
        type: PropTypes.string.isRequired,
        img: PropTypes.string.isRequired,
      }),
      // TextsList
      PropTypes.shape({
        type: PropTypes.string.isRequired,
        textsList: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
      }),
      // ObjectsList
      PropTypes.shape({
        type: PropTypes.string.isRequired,
        objectsList: PropTypes.shape({
          image: PropTypes.string,
          title: PropTypes.string,
          text: PropTypes.string,
        }).isRequired,
      }),
    ])
  ).isRequired,
  title: PropTypes.string.isRequired,
  nextLink: PropTypes.string.isRequired,
};
export default GuidePage;
