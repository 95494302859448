/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { Link } from "react-router-dom";
// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import MKButton from "components/MKButton";
// Material Kit 2 React examples
import DefaultNavbar from "examples/Navbars/DefaultNavbar";
import DefaultFooter from "examples/Footers/DefaultFooter";

// Presentation page sections
import Divider from "@mui/material/Divider";
import DefaultInfoCard from "examples/Cards/InfoCards/DefaultInfoCard";

/*import DesignBlocks from "pages/Presentation/sections/DesignBlocks";*/
// Presentation page components

// Routes
import routes from "routes";

// Images
import bgImage from "assets/images/bkg.jpg";

function Presentation() {
  return (
    <>
      <DefaultNavbar routes={routes} sticky />
      <MKBox
        minHeight="75vh"
        width="100%"
        sx={{
          backgroundImage: `url(${bgImage})`,
          backgroundSize: "cover",
          backgroundPosition: "top",
          display: "grid",
          placeItems: "center",
        }}
      >
        <Container>
          <Grid container item xs={12} lg={7} justifyContent="center" mx="auto">
            <MKTypography
              textAlign="left"
              variant="h1"
              color="white"
              mt={-6}
              mb={1}
              sx={({ breakpoints, typography: { size } }) => ({
                [breakpoints.down("md")]: {
                  fontSize: size["3xl"],
                },
              })}
            >
              A DIY web radio.{" "}
            </MKTypography>
            <MKTypography
              variant="body1"
              color="white"
              textAlign="left"
              px={{ xs: 6, lg: 12 }}
              mt={1}
            >
              Make your own web radio with a raspberry pi and few components for less than 50$!
              <br />
              Print the enclosures with our free 3D model or make your own and share it with the
              rest of the community!
            </MKTypography>
          </Grid>
        </Container>
      </MKBox>
      <Card
        sx={{
          p: 2,
          mx: { xs: 2, lg: 3 },
          mt: -8,
          mb: 4,
          backgroundColor: ({ palette: { white }, functions: { rgba } }) => rgba(white.main, 0.8),
          backdropFilter: "saturate(200%) blur(30px)",
          boxShadow: ({ boxShadows: { xxl } }) => xxl,
        }}
      >
        <MKBox component="section" py={3}>
          <Container>
            <Grid container item xs={12} lg={12} justifyContent="center" mx="auto">
              <MKTypography
                textAlign="left"
                variant="h1"
                mb={6}
                mt={4}
                sx={({ breakpoints, typography: { size } }) => ({
                  [breakpoints.down("md")]: {
                    fontSize: size["3xl"],
                  },
                })}
              >
                What it does{" "}
              </MKTypography>
              <MKTypography variant="body1" textAlign="left" px={{ xs: 10, lg: 12 }} mb={6}>
                The PiWebRadio is a DIY internet radio, fully customizable, 3D printable, solderless
                kit! It comes with a fully coded web application that launches a server on your LAN
                network on wich you can connect though browser or our mobile app to edit the radios
                list.
              </MKTypography>
            </Grid>
          </Container>
          <Container>
            <Grid container item xs={12} lg={9} sx={{ mx: "auto" }}>
              <Grid item xs={12} md={4}>
                <DefaultInfoCard
                  icon="radio"
                  title="Internet radios"
                  description="Add your favourites internet radios url and enjoy them easily on your PiWebRadio"
                />
              </Grid>
              <Grid item xs={12} md={4} display="flex">
                <Divider
                  orientation="vertical"
                  sx={{ display: { xs: "none", md: "block" }, mx: 0 }}
                />
                <DefaultInfoCard
                  icon="touch_app"
                  title="Simple interface"
                  description="Two knobs, speakers and a screen... That's all you need to enjoy your favourites radios!"
                />
                <Divider
                  orientation="vertical"
                  sx={{ display: { xs: "none", md: "block" }, ml: 0 }}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <DefaultInfoCard
                  icon="devices"
                  title="Web app"
                  description="Access your PiWebRadio settings on any device through browser or mobile app to edit the radios list"
                />
              </Grid>
            </Grid>
          </Container>
          <Container>
            <Grid container item xs={12} lg={12} justifyContent="center" mx="auto">
              <MKTypography
                textAlign="left"
                variant="h1"
                mb={6}
                mt={12}
                sx={({ breakpoints, typography: { size } }) => ({
                  [breakpoints.down("md")]: {
                    fontSize: size["3xl"],
                  },
                })}
              >
                Full documentation{" "}
              </MKTypography>
              <MKTypography variant="body1" textAlign="left" px={{ xs: 10, lg: 12 }} mb={6}>
                Hardware connection, software installation, everything is explained from scratch
                into our guides!
              </MKTypography>
              <MKButton
                variant="gradient"
                color="info"
                size="large"
                component={Link}
                to="/pages/get-started"
                sx={{ mb: 2 }}
              >
                Get started
              </MKButton>
            </Grid>
          </Container>
        </MKBox>
      </Card>
      <MKBox>
        <DefaultFooter />
      </MKBox>
    </>
  );
}

export default Presentation;
